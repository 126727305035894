import React from 'react'
import tw, { styled } from 'twin.macro'
import Img from 'gatsby-image/withIEPolyfill'
import { useStaticQuery, graphql, Link } from 'gatsby'
import contentfulRichText from '../../lib/contentfulRichText'
import SetWidthSection from '../containers/SetWidthSection'

interface ServiceListProps {
  services: Service[]
}

const ServiceList: React.FC<ServiceListProps> = (props) => {
  const serviceList = props.services.map(({ node: service }, index) => {
    const serviceName = service.serviceName.replace(' ', '-').toLowerCase()
    const shortDescription = contentfulRichText(JSON.parse(service.shortDescription.raw), service.shortDescription.references)
    const serviceUrl = `/services/${serviceName}`
    return (
      <Service key={index} aria-labelledby={serviceName}>
        <ServiceGraphicContainer>
          <ServiceImage
            // @ts-ignore
            fluid={service?.image?.localFile?.childImageSharp?.fluid}
            objectFit="cover"
            objectPosition="50% 50%"
            alt={service.serviceName}
          />
        </ServiceGraphicContainer>
        <AboutService>
          <ServiceTitle id={serviceName}>{service.serviceName}</ServiceTitle>
          <ServiceDescription>{shortDescription}</ServiceDescription>
          <ServiceCTA to={serviceUrl}>
            {service.ctaText}
          </ServiceCTA>
        </AboutService>
      </Service>
    )
  })

  return <>{serviceList}</>
}

const Services: React.FC = () => {
  const data = useStaticQuery<Data>(graphql`
    query {
      ourServicesTitle: contentfulTextCopy(key: { eq: "Our Services" }) {
        value
      }
      allContentfulService {
        edges {
          node {
            serviceName
            ctaText
            shortDescription {
              raw
              references {
                ... on ContentfulAsset {
                  contentful_id
                  fluid(maxWidth: 2500, quality: 60) {
                    aspectRatio
                    src
                    srcSet
                    sizes
                  }
                  fixed(width: 1600) {
                    width
                    height
                    src
                    srcSet
                  }
                }
              }
            }
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <ServicesBG aria-labelledby="services-heading">
      <SetWidthSection>
        <ServicesContainer>
          <ServicesInnerContainer>
            <Header id="services-heading">{data.ourServicesTitle.value}</Header>
            <ServiceList services={data.allContentfulService.edges} />
          </ServicesInnerContainer>
        </ServicesContainer>
      </SetWidthSection>
    </ServicesBG>
  )
}

const ServicesBG = tw.div`bg-gradient-to-br from-gray-900 to-gray-700`
const ServicesContainer = tw.div`px-4`
const ServicesInnerContainer = tw.div`relative -mt-overlap bg-white shadow-2xl rounded-sm border-4 border-blue-400 px-3 py-12 sm:py-16 md:p-8 lg:p-24`
const Header = tw.h2`
  text-shadow-lg border-b-2 border-blue-600 
  pb-6 mb-2 mx-auto text-center
  leading-9 font-heading text-blue-900 text-4xl
  sm:(text-5xl leading-10 w-1/2)
  md:mb-6
  lg:(text-6xl leading-none)`
const Service = tw.div`flex flex-wrap items-center`
const ServiceGraphicContainer = styled.div<{ flip?: boolean }>`
  ${(props) =>
    props.flip
      ? tw`md:w-2/5 xl:w-3/5 mb-8 order-none`
      : tw`md:w-2/5 xl:w-3/5 mb-8 md:order-1`};
`
const ServiceImage = tw(Img)`w-full mx-auto`
const AboutService = tw.div`md:w-3/5 xl:w-2/5 px-6 mb-8`
const ServiceTitle = tw.div`text-shadow-md text-3xl md:text-4xl mb-3 font-heading`
const ServiceDescription = tw.div`text-gray-800 leading-relaxed`
const ServiceCTA = tw(
  Link,
)`text-center mt-4 inline-flex text-white bg-blue-700 border-0 py-2 px-6 focus:outline-none hover:bg-blue-400 rounded-sm text-base transition-transform transform active:scale-95 duration-100`

interface Data {
  ourServicesTitle: {
    value: string
  }
  allContentfulService: {
    edges: Service[]
  }
}

interface Service {
  node: {
    serviceName: string
    shortDescription: {
      raw: string
    }
    ctaText: string
    image: {
      localFile: {
        childImageSharp: {
          fluid: unknown
        }
      }
    }
  }
}

export default Services
